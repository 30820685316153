<script>
import Layout from "../../../../../../layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
import TypeMaintform from "./components/TypeMaintform.vue";

import { fetchMaintTypeApi } from "@/api/common";
import Swal from "sweetalert2";
export default {
  import: {fetchMaintTypeApi},
  page: {
    title: "Types de Maintenance",
    meta: [{ name: "description", content: appConfig.description }],
  },
  components: { Layout, PageHeader, TypeMaintform },
  data() {
    return {
      selectedTypeMaint: null,
      tableData: [],
      List_TypeMaint: [],
      rows:'',
      active:false,
      selectedRows:[],
      typeMaintenance:undefined,
      title: "Types de Maintenance",
      items: [
        {
          text: "Paramètres",
          href: "javascript:void(0)",
        },
        {
          text: "Contrats",
          href: "javascript:void(0)",
        },
        {
          text :"Restauration",
          href : "base.settings.prestations.index"
        },
        {
          text: "Types de Maintenance",
          active: true,
        },
      ],
      currentPage: 1,
      perPage: 10,
      pageOptions: [10, 25, 50, 100],
      filter: null,
      filterOn: [],
      sortDesc: false,
      sortBy: "name",
      fields: [
        { key: "name", sortable: true },
        { key: "type", sortable: true },
        { key: "classe", sortable: true },
      ],
    }
  },
   mounted() {
    // Set the initial number of items
    this.totalRows = this.tableData.length;
    // fetch divisions from api
    this.fetchTypeMaintList();
  },
   watch:{
      selectedRows(){
        console.log(this.selectedRows);
      },
      typeMaintenance:{
          handler(val){
            this.List_TypeMaint.push({
                name:val.name,
                description:val.description  
            });
            console.log(this.List_TypeMaint);
          }
          
          
      }
  },
  methods:{
    fetchTypeMaintList(){
      fetchMaintTypeApi()
      .then(res => (this.data = res.data.original.list))
      .catch(() => {
          // error.response.status Check status code
          
      }).finally(() => {
          //Perform action in always
      });
    },

    getTypeMaint(){
      this.$http.get('/base/maintenance/TypeMaintenance/list')
      .then(response => {
        console.log(response.data);
          this.data = response.data;
          this.selectedRows.push(response.data[0]);
      })
      .catch(error => console.log(error))
    },
    refreshTypeMaint(){
      this.getTypeMaint();
    },
     deleteTypeMaint(typeName, typeUid) {
      var _this = this;
      Swal.fire({
        title: "Êtes-vous sûr de supprimer le type :" + typeName + "?",
        text: "Vous ne pourrez pas annuler cela!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#34c38f",
        cancelButtonColor: "#f46a6a",
        confirmButtonText: "Supprimer!",
      }).then((result) => {
        if (result.value) {
          this.$http
            .post("/base/maintenance/TypeMaintenance/delete/" + typeUid)
            .then((res) => {
              var status = res.data.original.status;
              switch (status) {
                case 200:
                    this.$toast.success(res.data.original.msg);
                  _this.fetchOpsList();
                  break;

                case 500:
                  this.$toast.warning(res.data.original.msg);
                  break;
              }
            })
            .catch((error) => {
              this.$toast.error(error.message);
            })
            .finally(() => {});
        }
      });
    },
    editTypeMaint(typeUid) {
      let loader = this.$loading.show();
      this.$http.post("base/maintenance/TypeMaintenance/getSingleTypeMaintData/" + typeUid)
        .then((res) => {
          var status = res.data.original.status;
          var typeMaintenance = res.data.original.data.typeMaintenance;
          
          loader.hide();
          switch (status) {
            case 200:
                this.currentSelectedTypeMaint = typeMaintenance;
                this.updateTabsUi();
            break;

            case 500:
              this.$toast.warning(res.data.original.msg);
            break;
          }
        })
        .catch((error) => {
          this.$toast.error(error.message);
        })
        .finally(() => {});
    },

    handleLoadTypeMaintDataToFormComponent(selectedTypeMaint){
      if(selectedTypeMaint){
        this.editTypeMaint(selectedTypeMaint.uuid);
        
      }
    },  

    updateTabsUi(){
      this.generalInfosSetUp = Boolean(this.currentSelectedTypeMaint.general_infos_setup);
      this.scopeSetUp = Boolean(this.currentSelectedTypeMaint.scope_setup);
      this.financeInfosSetUp = Boolean(this.currentSelectedTypeMaint.financial_infos_setup);
      this.priceListInfosSetUp = Boolean(this.currentSelectedTypeMaint.pricelist_infos_setup);
      this.synopticsInfosSetUp = Boolean(this.currentSelectedTypeMaint.synptics_infos_setup);
    },

    handleGeneralInfoStepSavedEvent(typeMaintenance){

      this.currentSelectedTypeMaint = typeMaintenance;

      this.generalInfosSetUp = true;
      // refresh contracts list
      this.shouldRefreshTypeMaintTable = true;
      // jump to next tab
      this.formStep = 1;
    },
  }
  
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
      <div class="row">
      <div class="col-12">
        <div class="accordion mb-2" role="tablist">
          <b-card no-body class="mb-1">
            <b-card-header header-tag="header" class="p-1" role="tab">
              <b-button block @click="active = !active" style="background:#e5e9f0; color:black !important; border:none !important;">
                <div class="row">
                  <div class="col text-left mt-1"> <i class="mdi mdi-format-list-bulleted"></i> Liste des types </div>
                  <div class="col text-right">
                    <i v-if="active" class="mdi mdi-chevron-up font-size-18"></i> 
                    <i v-else class="mdi mdi-chevron-down font-size-18"></i> 
                  </div>
                </div>
              </b-button>
            </b-card-header>
            <b-collapse id="accordion-1" :visible="active" accordion="my-accordion" role="tabpanel">
              <b-card-body class="shadow-lg">
              <div class="table-responsive mb-0 shadow">
                <!-- <table class="table table-centered table-nowrap"> -->
                  <v-table
                    class="table table-hover"
                    :data="List_TypeMaint"
                    selectionMode="single"
                    selectedClass="table-info"
                     @selectionChanged="maintenanceTableRowSelected($event)"
                  >
                  <thead slot="head" class="thead-light">
                    <tr>
                      <th>Désignation</th>
                      <th>Description</th>
                      <th>Actions</th>

                    </tr>
                  </thead>
                  <tbody slot="body" >
                    <v-tr v-for="row in data" :key="row.id" :row="row">
                      <td>
                        {{ data.name }}
                      </td>
                      <td>{{ data.description }}</td>
                      <td>
                        
                        <a
                          @click="editBrand(row.uuid)"
                          class="mr-3 text-primary"
                          data-toggle="tooltip"
                          data-placement="top"
                          title="Modifier"
                          data-original-title="Edit"
                          ><i class="mdi mdi-pencil font-size-18"></i
                        ></a>

                        <a
                          @click="deleteBrand(row.type, row.uuid)"
                          class="text-danger"
                          data-toggle="tooltip"
                          data-placement="top"
                          title="Supprimer"
                          data-original-title="Delete"
                          ><i class="mdi mdi-close font-size-18"></i
                        ></a>
                      </td> 
                    </v-tr>
                  </tbody>
                </v-table>
              </div>
              </b-card-body>
            </b-collapse>
          </b-card>
        </div>

        <div class="card mt-3">
            <TypeMaintform :base="selectedRows"  :typeMaintenance="currentSelectedTypeMaint"  />
        </div>
      </div>
    </div>
  </Layout>
</template>